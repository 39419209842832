.Tooltip {
  position: relative;
  display: inline-block;
  margin-left: 4px;
  cursor: pointer;
}

.Tooltip .text {
  visibility: hidden;
  width: 200px;
  background-color: white;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: 300;
  color: #371a32;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
}

.Tooltip .text.top {
  bottom: 100%;
  transform: translateX(calc(-50% + 7px));
}

.Tooltip .text.bottom {
  top: 100%;
  transform: translateX(calc(-50% + 7px));
}

.Tooltip .text.left {
  transform: translateY(calc(-50% + 7px));
  right: calc(100% + 5px);
}

.Tooltip .text.right {
  transform: translateY(calc(-50% +7px));
  left: calc(100% + 5px);
}

.Tooltip .text::after {
  content: ' ';
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.Tooltip .text.top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: white transparent transparent transparent;
}

.Tooltip .text.bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent white transparent;
}

.Tooltip .text.left::after {
  top: calc(50% + 7px);
  left: 100%;
  margin-top: -5px;
  border-color: transparent transparent transparent white;
}

.Tooltip .text.right::after {
  top: calc(50% + 7px);
  right: 100%;
  margin-top: -5px;
  border-color: transparent white transparent transparent;
}

.Tooltip:hover .text {
  visibility: visible;
}
