@tailwind base;
@tailwind components;
@tailwind utilities;

.react-autosuggest__container {
  position: relative;
  width: 100%;
}

@layer base {
  @font-face {
    font-family: 'HalyardText';
    src: url('/fonts/HalyardText/Halyard-Text-ExtraLight.ttf');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
  }

  @font-face {
    font-family: 'HalyardText';
    src: url('/fonts/HalyardText/Halyard-Text-Light.ttf');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'HalyardText';
    src: url('/fonts/HalyardText/Halyard-Text-Book-Regular.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'HalyardText';
    src: url('/fonts/HalyardText/Halyard-Text-Medium.ttf');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'HaylardText';
    src: url('/fonts/HalyardText/Halyard-Text-SemiBold.ttf');
    font-style: bold;
    font-weight: 600;
    font-display: swap;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .insight-content {
    @apply font-sans font-[350];
  }

  @media (max-width: 767px) {
    body,
    p {
      @apply font-sans text-[1rem] leading-[1.5625rem] text-sp-purple;
    }

    .seo-copy {
      @apply font-sans text-[0.875rem] font-[350] leading-[1.25rem] text-sp-mid-grey;
    }

    .body-text {
      @apply font-sans text-[1.125rem] font-[350] leading-[1.75rem];
    }

    h1 {
      @apply font-sans text-[1.75rem] font-semibold leading-[2.375rem];
    }

    h2 {
      @apply font-sans text-[1.75rem] font-[350] leading-[2.375rem];
    }

    h3,
    .quote {
      @apply font-sans text-[1.5rem] font-[350] leading-[1.75rem];
    }

    h1.insight-title {
      @apply font-sans text-[2rem] font-[350] leading-[2.375rem];
    }

    h1.insight-content {
      @apply text-[2rem] leading-[2.375rem];
    }

    h2.insight-content {
      @apply text-[1.75rem] leading-[2rem];
    }

    h3.insight-content,
    h4.insight-content,
    h5.insight-content,
    h6.insight-content {
      @apply text-[1.25rem] leading-[1.75rem];
    }

    .body-large {
      @apply font-sans text-[1.25rem] font-[350] leading-[1.75rem];
    }

    .body-medium {
      @apply font-sans text-[1rem] font-[350] leading-[1.5625rem];
    }

    .body-small {
      @apply font-sans text-[1rem] font-[350] leading-[1.5625rem];
    }

    .body-extra-small {
      @apply font-sans text-[0.875rem] font-[350] leading-[1.25rem];
    }

    .bullet-sub-heading {
      @apply font-sans text-[1.125rem] font-normal leading-[1.625rem];
    }

    .sub-heading-large {
      @apply font-sans text-[1.125rem] font-[350] leading-[1.75rem];
    }

    .sub-heading-medium {
      @apply font-sans text-[1.125rem] font-[350] leading-[1.75rem];
    }

    .sub-heading-small {
      @apply font-sans text-[1rem] font-semibold leading-[1.625rem];
    }

    .extra-large-label {
      @apply font-sans text-[2.25rem] font-[350] leading-[3.5rem];
    }

    .large-label {
      @apply font-sans text-[3.625rem] font-[350] leading-[3.25rem];
    }

    .medium-label {
      @apply font-sans text-[1.75rem] font-normal leading-[2rem];
    }

    .small-label {
      @apply font-sans text-[0.875rem] font-[350] leading-[0.875rem];
    }

    .extra-small-label {
      @apply font-sans text-[0.625rem] font-normal leading-[0.875rem];
    }

    .large-markdown p {
      @apply font-sans text-[1.125rem] font-normal leading-[1.625em];
    }

    .large-markdown a {
      @apply font-sans text-[1.125rem] font-normal leading-[1.625rem] text-sp-primary underline;
    }

    .medium-markdown p {
      @apply font-sans text-[1rem] font-[350] leading-[1.5rem];
    }

    .medium-markdown a {
      @apply font-sans text-[1rem] font-[350] leading-[1.5rem] text-sp-primary underline;
    }

    .button {
      @apply font-sans text-[1.125rem] font-normal leading-[1.75rem];
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    body,
    p {
      @apply font-sans text-[1.125rem] leading-[1.75rem] text-sp-purple;
    }

    .seo-copy {
      @apply font-sans text-[0.875rem] font-[350] leading-[1.25rem] text-sp-mid-grey;
    }

    .body-text {
      @apply font-sans text-[1.25rem] font-[350] leading-[1.75rem];
    }

    h1 {
      @apply font-sans text-[3rem] font-semibold leading-[3.25rem];
    }

    h2 {
      @apply font-sans text-[3rem] font-[350] leading-[3.25rem];
    }

    h3,
    .quote {
      @apply font-sans text-[2rem] font-[350] leading-[2.25rem];
    }

    h1.insight-title {
      @apply font-sans text-[2.5rem] font-[350] leading-[3rem];
    }

    h1.insight-content {
      @apply text-[2.25rem] leading-[3rem];
    }

    h2.insight-content {
      @apply text-[1.75rem] leading-[2.5rem];
    }

    h3.insight-content,
    h4.insight-content,
    h5.insight-content,
    h6.insight-content {
      @apply text-[1.25rem] leading-[2rem];
    }

    .body-large {
      @apply font-sans text-[1.5rem] font-[350] leading-[2rem];
    }

    .body-medium {
      @apply font-sans text-[1.125rem] font-[350] leading-[1.75rem];
    }

    .body-small {
      @apply font-sans text-[1rem] font-[350] leading-[1.5625rem];
    }

    .body-extra-small {
      @apply font-sans text-[0.875rem] font-[350] leading-[1.25rem];
    }

    .bullet-sub-heading {
      @apply font-sans text-[1.5rem] font-normal leading-[1.75rem];
    }

    .sub-heading-large {
      @apply font-sans text-[2rem] font-[350] leading-[2.625rem];
    }

    .sub-heading-medium {
      @apply font-sans text-[1.25rem] font-[350] leading-[1.75rem];
    }

    .sub-heading-small {
      @apply font-sans text-[1.125rem] font-semibold leading-[1.625rem];
    }

    .extra-large-label {
      @apply font-sans text-[2.25rem] font-[350] leading-[3.5rem];
    }

    .large-label {
      @apply font-sans text-[3.625rem] font-[350] leading-[3.25rem];
    }

    .medium-label {
      @apply font-sans text-[1.75rem] font-normal leading-[2rem];
    }

    .small-label {
      @apply font-sans text-[0.875rem] font-[350] leading-[0.875rem];
    }

    .extra-small-label {
      @apply font-sans text-[0.625rem] font-normal leading-[0.625rem];
    }

    .large-markdown p {
      @apply font-sans text-[1.375rem] font-normal leading-[2rem];
    }

    .large-markdown a {
      @apply font-sans text-[1.375rem] font-normal leading-[2rem] text-sp-primary underline;
    }

    .medium-markdown p {
      @apply font-sans text-[1.125rem] font-[350] leading-[1.75rem];
    }

    .medium-markdown a {
      @apply font-sans text-[1.125rem] font-[350] leading-[1.75rem] text-sp-primary underline;
    }

    .button {
      @apply min-w-[234px] font-sans text-[1.125rem] font-normal leading-[1.75rem];
    }
  }

  @media (min-width: 1024px) {
    body,
    p {
      @apply font-sans text-[1.125rem] leading-[1.75rem] text-sp-purple;
    }

    .seo-copy {
      @apply font-sans text-[0.875rem] font-[350] leading-[1.25rem] text-sp-mid-grey;
    }

    .body-text {
      @apply font-sans text-[1.25rem] font-[350] leading-[1.75rem];
    }

    h1 {
      @apply font-sans text-[3.5rem] font-semibold leading-[4rem];
    }

    h2 {
      @apply font-sans text-[3.625rem] font-[350] leading-[4.375rem];
    }

    h3,
    .quote {
      @apply font-sans text-[2.375rem] font-[350] leading-[2.875rem];
    }

    h1.insight-title {
      @apply font-sans text-[3rem] font-[350] leading-[4rem];
    }

    h1.insight-content {
      @apply text-[2.75rem] leading-[3.5rem];
    }

    h2.insight-content {
      @apply text-[2.375rem] leading-[3rem];
    }

    h3.insight-content {
      @apply text-[1.875rem] leading-[2.5rem];
    }

    h4.insight-content {
      @apply text-[1.75rem] leading-[2rem];
    }

    h5.insight-content {
      @apply text-[1.5rem] leading-[2rem];
    }

    h6.insight-content {
      @apply text-[1.25rem] leading-[2rem];
    }

    .body-large {
      @apply font-sans text-[1.75rem] font-[350] leading-[2.375rem];
    }

    .body-medium {
      @apply font-sans text-[1.125rem] font-[350] leading-[1.75rem];
    }

    .body-small {
      @apply font-sans text-[1rem] font-[350] leading-[1.5625rem];
    }

    .body-extra-small {
      @apply font-sans text-[0.875rem] font-[350] leading-[1.25rem];
    }

    .bullet-sub-heading {
      @apply font-sans text-[1.5rem] font-normal leading-[1.75rem];
    }

    .sub-heading-large {
      @apply font-sans text-[2rem] font-[350] leading-[2.625rem];
    }

    .sub-heading-medium {
      @apply font-sans text-[1.25rem] font-[350] leading-[1.75rem];
    }

    .sub-heading-small {
      @apply font-sans text-[1.125rem] font-semibold leading-[1.625rem];
    }

    .extra-large-label {
      @apply font-sans text-[2.25rem] font-[350] leading-[3.5rem];
    }

    .large-label {
      @apply font-sans text-[3.625rem] font-[350] leading-[3.25rem];
    }

    .medium-label {
      @apply font-sans text-[1.75rem] font-normal leading-[2rem];
    }

    .small-label {
      @apply font-sans text-[0.875rem] font-[350] leading-[0.875rem];
    }

    .extra-small-label {
      @apply font-sans text-[0.625rem] font-normal leading-[0.625rem];
    }

    .large-markdown p {
      @apply font-sans text-[1.375rem] font-normal leading-[2rem];
    }

    .large-markdown a {
      @apply font-sans text-[1.375rem] font-normal leading-[2rem] text-sp-primary underline;
    }

    .medium-markdown p {
      @apply font-sans text-[1.125rem] font-[350] leading-[1.75rem];
    }

    .medium-markdown a {
      @apply font-sans text-[1.125rem] font-[350] leading-[1.75rem] text-sp-primary underline;
    }

    .button {
      @apply min-w-[234px] font-sans text-[1.125rem] font-normal leading-[1.75rem];
    }
  }
}

.react-markdown > * {
  all: revert;
}

.react-markdown p,
.react-markdown h1,
.react-markdown h2,
.react-markdown h3,
.react-markdown h4,
.react-markdown h5,
.react-markdown h6 {
  @apply mt-4;
  @apply mb-0;
  @apply mx-0;
}

.react-markdown ul,
.react-markdown ol {
  @apply m-0;
}

.react-markdown a {
  @apply underline;
  @apply text-sp-primary;
}

.react-markdown > :first-child {
  @apply m-0;
}
